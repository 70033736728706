import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Tag from "../components/tag";


const TagsPage = ({data}) => {

    const tags = data.allTags.group;


    return(
        <Layout>
            <SEO title="Tags" keywords={["tags"]}/>
            <div className="tags">
                <div className="head">/tags</div>

                <div className="list">
                {
                    tags.map(t => (<Tag title={t.tag}/>) )
                }
                </div>

            </div>
        </Layout>
    );

}
export default TagsPage;

export const query = graphql`
query {
    allTags: allMarkdownRemark {
        group(field: frontmatter___tags) {
            tag: fieldValue
            totalCount
        }
    }
}
`;